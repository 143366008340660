<template>
  <v-container fluid>
    <v-row class="mb-1 ml-5">
      <v-col cols="6" class="pl-0">
        <h1 class="display-1">
          {{ $t("savings") }}
          <v-btn
            icon
            small
            color="textColor"
            class="refresh"
            @click="refreshData()"
            >
            <v-icon small>mdi-autorenew</v-icon>
          </v-btn>
        </h1>
        <span class="subtitle-2 sub">{{ $t("savingsSubtitle") }}</span>
        </v-col>
        <v-col cols="6" class="text-right pr-0">
        <v-select
          class="main-chart-select dashboard-main-select textColor--text mt-10"
          color="textColor"
          dense
          flat
          single-line
          hide-details
          :items="select"
          underlined
          label="Oct 2021 - Apr 2022"
          ></v-select>
      </v-col>
    </v-row>
    <div class="dashboard-page">
      <v-row no-gutters class="mt-1">
        <v-row justify="space-between">
        <DeviceOverviewCard
            :title="$t('eligible_devices')"
            :title_number="numHomes"
            text_font_size="30px"
            text_font_weight="normal"
            card_icon="mdi-home"
            card_icon_color="gray"
            card_size=3
        ></DeviceOverviewCard>
        <DeviceOverviewCard
            :title="$t('actual_consumption')"
            :title_number="totalConsumption"
            text_font_size="30px"
            text_font_weight="normal"
            unit="kwh"
            card_icon="mdi-gas-burner"
            card_icon_color="#3E7DC4"
            card_size=3
        ></DeviceOverviewCard>
        <DeviceOverviewCard
            :title="$t('energy_savings')"
            :title_number="energySavings"
            text_font_size="30px"
            text_font_weight="normal"
            unit="kwh"
            card_icon="mdi-leaf"
            card_icon_color="#50E8A6"
            card_size=3
        ></DeviceOverviewCard>
          <DeviceOverviewCard
            :title="$t('energy_eff_improv')"
            :title_number="energyEfficiencyImprovement"
            text_font_size="30px"
            text_font_weight="normal"
            card_icon="mdi-chart-line-variant"
            card_icon_color="blue"
            unit="percentage"
            card_size=3
          ></DeviceOverviewCard>
          <DeviceOverviewCard
            :title="$t('baseline_days')"
            :title_number="numBaselineDays"
            text_font_size="30px"
            text_font_weight="normal"
            card_icon="mdi-calendar"
            card_icon_color="#FFBC48"
            card_size=3
        ></DeviceOverviewCard>
          <DeviceOverviewCard
            :title="$t('co2_red')"
            :title_number="co2Reduction"
            text_font_size="30px"
            text_font_weight="normal"
            card_icon="mdi-molecule-co2"
            card_icon_color="gray"
            unit="kg"
            card_size=3
          ></DeviceOverviewCard>
          <DeviceOverviewCard
            :title="$t('cost_red')"
            :title_number="costReduction"
            text_font_size="30px"
            text_font_weight="normal"
            card_icon="mdi-cash-multiple"
            card_icon_color="green"
            unit="euro"
            card_size=3
          ></DeviceOverviewCard>
          <DeviceOverviewCard
            :title="$t('sup_cost_red')"
            :title_number="supplierCostReduction"
            text_font_size="30px"
            text_font_weight="normal"
            card_icon="mdi-finance"
            card_icon_color="red"
            unit="euro"
            card_size=3
          ></DeviceOverviewCard>
          <v-col v-if="chartActive" cols="12" md="12">
            <v-card class="pb-0">
              <v-card-title class="pa-6 pb-0">
                <v-row no-gutters>
                  <v-col
                    cols="7"
                    sm="4"
                    md="4"
                    lg="5"
                    class="d-flex align-center"
                  >
                    <p>{{ $t("consumption_kwh") }}</p>
                  </v-col>
                  <v-col
                    sm="6"
                    md="6"
                    lg="5"
                    class="d-none d-sm-flex align-center"
                  >
                  </v-col>
                  <v-col cols="5" sm="2" md="2" lg="2" >
                    <v-menu>
                      <template v-slot:activator="{attrs }">
                        <v-select
                          class="main-chart-select"
                          v-model="consumptionApexAreaSelect"
                          v-bind="attrs"
                          @change="changeConsumptionDataTimeRange"
                          dense
                          flat
                          single-line
                          hide-details
                          :items="selectTimeRange"
                          item-text="text"
                          item-value="query"
                          outlined
                        ></v-select>
                      </template>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-title>
                <ApexChart
                  v-if="apexLoading"
                  height="350"
                  type="bar"
                  :options="chartOptions"
                  :series="devicesSavingsData"
                ></ApexChart>
            </v-card>
          </v-col>
          <v-col v-if="chartActive" cols="12" md="12">
            <v-card class="pb-0">
              <v-card-title class="pa-6 pb-0">
                <v-row no-gutters>
                  <v-col
                    cols="7"
                    sm="4"
                    md="4"
                    lg="5"
                    class="d-flex align-center"
                  >
                    <p>{{ $t("climate") }}</p>
                  </v-col>
                  <v-col
                    sm="6"
                    md="6"
                    lg="5"
                    class="d-none d-sm-flex align-center"
                  >
                  </v-col>
                  <v-col cols="5" sm="2" md="2" lg="2" >
                    <v-menu>
                      <template v-slot:activator="{attrs }">
                        <v-select
                          class="main-chart-select"
                          v-model="consumptionApexAreaSelect"
                          v-bind="attrs"
                          @change="changeConsumptionDataTimeRange"
                          dense
                          flat
                          single-line
                          hide-details
                          :items="selectTimeRange"
                          item-text="text"
                          item-value="query"
                          outlined
                        ></v-select>
                      </template>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-title>
                <ApexChart
                  v-if="apexLoading"
                  height="350"
                  type="area"
                  :options="chartOptions"
                  :series="devicesClimateData"
                ></ApexChart>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import ApexChart from "vue-apexcharts";
import config from "@/config";
import { commonModule, devicesModule, distributorModule, statsModule, companyModule } from '@/store/modules/store-accessor'
import DashboardTodaysAppointmentsTable from "../components/Tables/DashboardTodaysAppointmentsTable.vue";
import DashboardActiveFaultsTable from "../components/Tables/DashboardActiveFaultsTable.vue";
import DashboardCardBarChart from "../components/Cards/DashboardCardBarChart.vue";
import DashboardCardPieChart from "../components/Cards/DashboardCardPieChart.vue";
import DashboardCardStats from "../components/Cards/DashboardCardStats.vue";
import DeviceOverviewCard from "@/components/Cards/DeviceOverviewCard.vue";
import TheLoadingIndicator from '@/components/TheLoadingIndicator.vue'
import KeycloakSession from '@/helpers/keycloak-session'
import { TimeUtil } from '@/helpers/TimeUtil'
import i18n from "@/i18n";


@Component({
  components: {
    ApexChart,
    DashboardCardBarChart,
    DashboardCardPieChart,
    DashboardCardStats,
    DeviceOverviewCard,
    DashboardTodaysAppointmentsTable,
    TheLoadingIndicator,
    DashboardActiveFaultsTable,
  },
})
export default class Savings extends Vue {

  get kcSession() {
    return new KeycloakSession()
  }

  get isDistributor() {
    return this.kcSession.userHasRole('distributor');
  }

  get isTechnicianCompany () {
    return this.kcSession.userHasRole('technician_company');
  }

  get devicesConsumptionData() {
    return [ devicesModule.devicesConsumptionData.kwh ]
  }

  get devicesSavingsData() {
    return [ devicesModule.devicesSavingsData.kwh, devicesModule.devicesSavingsData.kwh_savings, devicesModule.devicesSavingsData.kwh_baseline ]
  }

  get devicesClimateData() {
    return [ devicesModule.devicesSavingsData.t_out ]
  }

  get chartActive() {
    // return companyModule.company.distributor_logo_link;
    if(this.isDistributor) {
       if (companyModule.company.email == 'info@domx.io') {
        return true;
       }
    }

    return true;
  }

  selectTimeRange = [
    { query: {hours: '24'}, text: this.$i18n.t("hourly_data") },
    { query: {days: '2'}, text: this.$i18n.t("daily_data") },
    { query: {days: '7'}, text: this.$i18n.t("weekly_data") }, 
    { query: {days: '30'}, text: this.$i18n.t("monthly_data") }
  ]

  consumptionApexAreaSelect = this.selectTimeRange[1];
  climateApexAreaSelect = this.selectTimeRange[1];

  async changeConsumptionDataTimeRange(selection) {
    const arrayItem = this.selectTimeRange.find(o => { return o.query === selection}) ;
    
    if(arrayItem != undefined) {
      this.roomTemperaturesApexAreaSelect = arrayItem
    }

    await devicesModule.getDeviceSavingsData(selection);
    await devicesModule.getDeviceSavingsKPI(selection);
  }

  select = [
    this.$i18n.t("weekly"),
    this.$i18n.t("monthly"),
    this.$i18n.t("yearly"),
  ];

  apexLoading = false;
  actionsSelect = this.$i18n.t("weekly");

  chartOptions = {
    chart: {
      height: 380,
      width: "100%",
      type: "area",
      stacked: true,
      animations: {
        initialAnimation: {
          enabled: false
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      formatter: function(seriesName, opts) {

        return [i18n.t(seriesName)]
      }
    },
    yaxis: {
      decimalsInFloat: 1
    },
    xaxis: {
      type: 'datetime'
    },
    tooltip: {
      y: {
        formatter: (val) => {
         if(val) {
           return val.toFixed(1)
         }
        }
      },
      x: {
        formatter: (val) => {
          if(val) {
           return TimeUtil.formatInfluxDateTime(val) 
          }
        }
      }
    }
  };

  get energySavings() {
    return devicesModule.devicesSavingsKPI.energy_savings?.toFixed(1);
  }

  get totalConsumption() {
    return devicesModule.devicesSavingsKPI.total_kwh?.toFixed(1);
  }

  get numHomes() {
    return devicesModule.devicesSavingsKPI.num_homes;
  }

  get numBaselineDays() {
    return devicesModule.devicesSavingsKPI.num_baseline;
  }

  get energyEfficiencyImprovement() {
    return devicesModule.devicesSavingsKPI.energy_eff_improv?.toFixed(1);
  }

  get co2Reduction(){
    return devicesModule.devicesSavingsKPI.co2_red?.toFixed(1);
  }

  get costReduction(){
    return devicesModule.devicesSavingsKPI.cost_red?.toFixed(1);
  }

  get supplierCostReduction(){
    return devicesModule.devicesSavingsKPI.sup_cost_red?.toFixed(1);
  }

  async refreshData() {
    this.consumptionApexAreaSelect = this.selectTimeRange[1];
    this.climateApexAreaSelect = this.selectTimeRange[1];

    await devicesModule.getDeviceSavingsData({hours: '24'});
    await devicesModule.getDeviceSavingsKPI({hours: '24'});
  }

  async mounted () {
    setTimeout(() => {
      this.apexLoading = true;
    });

    await devicesModule.getDeviceSavingsData({hours: '24'});
    await devicesModule.getDeviceSavingsKPI({hours: '24'});
    
    companyModule.getCompanyInfo();

    devicesModule.getDeviceCount();
    devicesModule.getDevicesActiveFaults();
    devicesModule.getDevices('');
    
    if(this.isDistributor) {
      distributorModule.getInventoryCount();
    }
    else {
      devicesModule.getDeviceCount();
    }
    // distributorModule.getDevices('');
    commonModule.initSnackbar({});
    /*
     TODO: Handle installations properly when UX is clear.
     */
    statsModule.getStatsAction();
    await statsModule.getStatsLastMonthsAction();
    statsModule.SetisLoadingLastMonths(false);
    await statsModule.getStatsActionsAction();
    await statsModule.getStatsDevicesAction();
    statsModule.SetisLoadingActionStats(false);

  }
}
</script>

<style lang="scss">
@import "../styles/app";

.dashboard-page {
  .page-title {
    padding-bottom: 20px;
    @include media-breakpoint-up(sm) {
      padding-bottom: 0;
    }
  }
  .apexcharts-legend-series {
    padding: 2px 5px 3px;
    display: flex;
    align-items: center;
    .apexcharts-legend-marker {
      margin-right: 8px;
    }
  }
  .main-chart-select {
    font-size: 1rem;
    margin-right: 10px;
    max-width: 300px;
    fieldset {
      border-width: 1px !important;
      border-color: $text-grey !important;
      color: $text-grey !important;
    }
    .v-select__selection {
      color: $text-color !important;
    }
  }
  .v-card {
    .v-card__text {
      .subtext {
        font-size: 1.142rem;
        font-weight: 400;
        color: $title-grey;
      }
      .subtext-index {
        font-size: 11px;
        color: $text-color;
        font-weight: 400;
      }
    }
  }
}

.v-menu__content {
  box-shadow: $card-shadow !important;
  .v-list-item__content .v-list-item__title {
    font-size: 1rem;
    font-weight: 400;
  }
}
.button-shadow {
  box-shadow: $button-shadow;
}

.dashboard {
  position: relative;
}

h1 {
  margin-top: 40px;
  font-size: 35px;
  font-weight: 500;
  line-height: 65px;
}

.sub {
  font-weight: normal!important;
}

.dashboard-main-select {
  width: 450px;
 float: right;
}
</style>
