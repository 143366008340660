import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Layout from "../components/Layout/Layout.vue";

//pages
import Dashboard from "../views/Dashboard.vue";
import Savings from "../views/Savings.vue";
import Consumption from "../views/Consumption.vue";
import Clients from "../views/Clients.vue";
import Installations from "../views/Installations.vue"
import Companies from "../views/Companies.vue";
import Devices from "../views/Devices.vue";
import Inventory from "../views/Inventory.vue";
import Map from "../views/Map.vue";
import Users from "../views/Users.vue";
import Error from "../views/Error.vue";
import KeycloakSession from '@/helpers/keycloak-session'

Vue.use(VueRouter);

function isTechnicianCompany () {
  const kc = new KeycloakSession()
  return kc.userHasRole('technician_company');
}

function isDistributor () {
  const kc = new KeycloakSession()
  return kc.userHasRole('distributor');
}

const routes: Array<RouteConfig> = [
  { path: "/404", component: Error, name: "Error" },
  {
    path: "/",
    redirect: "dashboard",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "inventory",
        name: "Inventory",
        component: Inventory,
        beforeEnter: (to, from, next) => {
          // reject the navigation
          if (!isDistributor()) {
            next('/404');
          } else {
            next();
          }
        },
      },
      {
        path: "consumption",
        name: "Consumption",
        component: Consumption
      },
      {
        path: "savings",
        name: "Savings",
        component: Savings
      },
      {
        path: "clients",
        name: "Clients",
        component: Clients,
        beforeEnter: (to, from, next) => {
          // reject the navigation
          if (!isTechnicianCompany()) {
            next('/404');
          } else {
            next();
          }
        },
      },
      {
        path: "installations",
        name: "Installations",
        component: Installations,
      },
      {
        path: "companies",
        name: "Companies",
        component: Companies,
        beforeEnter: (to, from, next) => {
          // reject the navigation
          if (!isDistributor()) {
            next('/404');
          } else {
            next();
          }
        },
      },
      {
        path: "devices",
        name: "Devices",
        component: Devices,
        beforeEnter: (to, from, next) => {
          // reject the navigation
          if (!isTechnicianCompany()) {
            next('/404');
          } else {
            next();
          }
        },
      },
      {
        path: "map",
        name: "Map",
        component: Map,
      },
      {
        path: "users",
        name: "Users",
        component: Users,
        beforeEnter: (to, from, next) => {
          // reject the navigation
          if (!isTechnicianCompany()) {
            next('/404');
          } else {
            next();
          }
        },
      },
    ],
  },
  { path: "*", redirect: "/404" },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
